export const allColumns = [
  {
    title: 'DAU',
    dataIndex: 'dau',
    width: '100px',
    scopedSlots: {
      customRender: 'dau'
    }
  },
  {
    title: '流量请求',
    dataIndex: 'request',
    width: '100px',
    scopedSlots: {
      customRender: 'request'
    }
  },
  {
    title: '流量填充',
    dataIndex: 'response',
    width: '100px',
    scopedSlots: {
      customRender: 'response'
    }
  },
  {
    title: '流量填充率',
    dataIndex: 'requestFilledRate',
    width: '100px',
    scopedSlots: {
      customRender: 'requestFilledRate'
    }
  },
  {
    title: '展示',
    dataIndex: 'unitImpression',
    width: '100px',
    scopedSlots: {
      customRender: 'unitImpression'
    }
  },
  {
    title: '展示率',
    dataIndex: 'unitImpressionRate',
    width: '100px',
    scopedSlots: {
      customRender: 'unitImpressionRate'
    }
  },
  {
    title: '点击',
    dataIndex: 'unitClick',
    width: '100px',
    scopedSlots: {
      customRender: 'unitClick'
    }
  },
  {
    title: '点击率',
    dataIndex: 'unitClickRate',
    width: '100px',
    scopedSlots: {
      customRender: 'unitClickRate'
    }
  },
  {
    title: 'eCPM',
    dataIndex: 'unitEcpm',
    width: '100px',
    scopedSlots: {
      customRender: 'unitEcpm'
    }
  },
  {
    title: '收益',
    dataIndex: 'mediaUnitRevenue',
    width: '100px',
    scopedSlots: {
      customRender: 'mediaUnitRevenue'
    }
  },
  {
    title: '人均收益',
    dataIndex: 'arpu',
    width: '100px',
    scopedSlots: {
      customRender: 'arpu'
    }
  }
]
